import { Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import * as showDialog from '@/utils/showDialog';
import Image from 'next/image';

interface Props {
  // eslint-disable-next-line no-unused-vars
  imageCallBack?: (image: File[]) => void;
  // eslint-disable-next-line no-unused-vars
  videoCallBack?: (image: File[]) => void;
  isImage?: boolean;
  isVideo?: boolean;
  initialImageButton?: boolean;
  isEditImage?: boolean;
  editingImage?: string[];
  editImageUrl?: string;
  // eslint-disable-next-line no-unused-vars
  editImageCallBack?: (image: string[]) => void;
  maxImage?: number;
}

export default function UpLoadImageVideo({
  imageCallBack,
  videoCallBack,
  isImage,
  isVideo,
  initialImageButton,
  isEditImage,
  editingImage,
  editImageUrl,
  editImageCallBack,
  maxImage,
}: Props) {
  const { t } = useTranslation();
  const [image, setImage] = useState<File[]>([]);
  const [video, setVideo] = useState<File[]>([]);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const [videoPreviews, setVideoPreviews] = useState<string[]>([]);
  const [countImage, setCountImage] = useState(0);
  const [countVideo, setCountVideo] = useState(0);
  const [editImagePreviews, setEditImagePreviews] = useState<string[]>([]);

  useEffect(() => {
    if (isEditImage) {
      if (editingImage && editingImage.length > 0) {
        setEditImagePreviews(editingImage);
        setCountImage(editingImage?.length ?? 0);
      }
    }
  }, [editingImage, isEditImage]);
  const handleFileChange = async (e: any) => {
    e.preventDefault();
    const inputFiles = (e.target as HTMLInputElement).files;
    let uploadedImage = [...image];
    if (inputFiles) {
      let imgtype = ['image/jpeg', 'image/jpg', 'image/png'];
      let filesAmount = inputFiles.length;
      let previews = [...imagePreviews];
      let checkCountImage = countImage;
      for (let i = 0; i < filesAmount; i++) {
        let mbval = parseFloat((inputFiles[i].size / (1024 * 1024)).toFixed(2));
        if (!imgtype.includes(inputFiles[i].type)) {
          showDialog.showErrorWithOkButton({
            text: t(`refund:uploadimagefileonly`),
          });
          break;
        } else if (mbval > 5) {
          showDialog.showErrorWithOkButton({
            text: t(`refund:notmorethanfivemb`),
          });
          break;
        } else {
          if (checkCountImage < (maxImage ?? 5)) {
            uploadedImage = [...uploadedImage, inputFiles[i]];
            let reader = new FileReader();
            checkCountImage++;
            reader.onload = () => {
              if (reader.result !== null) {
                previews.push(reader.result as string);
              }
              if (previews.length === uploadedImage.length) {
                setImagePreviews(previews);
              }
            };
            reader.readAsDataURL(inputFiles[i]);
          }
        }
      }
      setCountImage(checkCountImage);
      setImage(uploadedImage);
      imageCallBack?.(uploadedImage);
    }
  };
  const handleDeleteImage = (index: number) => {
    const updateImage = [...image];
    const updateImagePreviews = [...imagePreviews];
    updateImage.splice(index, 1);
    updateImagePreviews.splice(index, 1);
    setImage(updateImage);
    setImagePreviews(updateImagePreviews);
    setCountImage(updateImagePreviews.length);
    imageCallBack?.(updateImage);
  };

  const handleDeleteEditImage = (index: number) => {
    const updateEditImagePreviews = [...editImagePreviews];
    updateEditImagePreviews.splice(index, 1);
    setEditImagePreviews(updateEditImagePreviews);
    setCountImage(countImage - 1);
    editImageCallBack?.(updateEditImagePreviews);
  };

  const handleVideoFileChange = async (e: any) => {
    e.preventDefault();
    const inputFiles = (e.target as HTMLInputElement).files;
    let uploadedVideo = [...video];
    let previews = [...videoPreviews];
    let checkCountVideo = countVideo;
    if (inputFiles) {
      let video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        if (video.duration > 60) {
          showDialog.showErrorWithOkButton({
            text: t(`pleaseuploadvideomore`),
          });
          return;
        } else {
          if (inputFiles[0]) {
            if (!inputFiles[0].type.match('video.*')) {
              showDialog.showErrorWithOkButton({
                text: t(`pleaseuploadonlyvideo`),
              });
            } else {
              if (uploadedVideo.length <= 0) {
                uploadedVideo = [...uploadedVideo, inputFiles[0]];
                checkCountVideo++;
                let reader = new FileReader();
                reader.onload = () => {
                  if (reader.result !== null) {
                    previews.push(reader.result as string);
                  }
                  setVideoPreviews(previews);
                };
                reader.readAsDataURL(inputFiles[0]);
              }
            }
            setCountVideo(checkCountVideo);
            setVideo(uploadedVideo);
            videoCallBack?.(uploadedVideo);
          }
        }
      };
      video.src = URL.createObjectURL(inputFiles[0]);
    }
  };
  const handleDeleteVideo = (index: number) => {
    const updateVideo = [...video];
    const updateVideoPreviews = [...videoPreviews];
    updateVideo.splice(index, 1);
    updateVideoPreviews.splice(index, 1);
    setVideo(updateVideo);
    setVideoPreviews(updateVideoPreviews);
    setCountVideo(updateVideo.length);
    videoCallBack?.(updateVideo);
  };
  return (
    <>
      <div className="flex flex-wrap gap-1">
        {(isImage || (!isImage && !isVideo)) && (
          <>
            {initialImageButton && countImage === 0 ? (
              <Button
                variant="outlined"
                component="label"
                sx={{
                  color: '#666',
                  border: '2px solid #bbb',
                  borderRadius: '5px',
                  width: '100%',
                  height: '66px',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  display: 'block',
                  lineHeight: 1,
                  padding: '0.25rem 0.25rem',
                  textAlign: 'center',
                  backgroundColor: '#fff',
                  mx: 'auto',
                  my: 'auto',
                  '&:hover': {
                    border: '2px solid #bbb',
                    backgroundColor: '#fff',
                  },
                }}
              >
                <div className="flex h-full px-2">
                  <i
                    className="bi bi-image my-auto"
                    style={{ verticalAlign: 'middle' }}
                  />
                  <div className="ml-auto m-auto">
                    <span className="my-auto">+ </span>
                    <span className="my-auto">{t('addimage')}</span>
                  </div>
                </div>

                <input
                  type="file"
                  onChange={handleFileChange}
                  accept="image/png,image/jpeg,image/jpg"
                  hidden
                  multiple
                />
              </Button>
            ) : (
              ((initialImageButton &&
                countImage > 0 &&
                countImage < (maxImage ?? 5)) ||
                (!initialImageButton && countImage < (maxImage ?? 5))) && (
                <Button
                  variant="outlined"
                  component="label"
                  sx={{
                    color: '#666',
                    border: '2px solid #bbb',
                    borderRadius: '5px',
                    width: '66px',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    display: 'block',
                    lineHeight: 1,
                    padding: '0.25rem 0.25rem',
                    textAlign: 'center',
                    backgroundColor: '#fff',
                    '&:hover': {
                      border: '2px solid #bbb',
                      backgroundColor: '#fff',
                    },
                  }}
                >
                  <div className="flex">
                    <span className="my-auto">+</span>
                    <i
                      className="bi bi-image ml-1"
                      style={{ verticalAlign: 'middle' }}
                    />
                  </div>
                  <span className="hover:text-black">
                    <span>{countImage}</span>/{maxImage ?? 5}
                  </span>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    accept="image/png,image/jpeg,image/jpg"
                    hidden
                    multiple
                  />
                </Button>
              )
            )}
          </>
        )}
        {(isVideo || (!isImage && !isVideo)) && (
          <>
            {countVideo < 1 && (
              <Button
                variant="outlined"
                component="label"
                sx={{
                  color: '#666',
                  border: '2px solid #bbb',
                  borderRadius: '5px',
                  width: '66px',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  display: 'block',
                  lineHeight: 1,
                  padding: '0.25rem 0.25rem',
                  textAlign: 'center',
                  backgroundColor: '#fff',
                  '&:hover': {
                    border: '2px solid #bbb',
                    backgroundColor: '#fff',
                  },
                }}
              >
                <div className="flex">
                  <span className="my-auto">+</span>
                  <i
                    className="bi bi-camera-video ml-1"
                    style={{ verticalAlign: 'middle' }}
                  />
                </div>
                <span className="hover:text-black">
                  <span>{t('video')}</span>
                </span>
                <input
                  type="file"
                  onChange={handleVideoFileChange}
                  accept="video/mp4,application/x-mpegURL,video/MP2T,video/3gpp,video/x-msvideo,video/x-ms-wm,video/quicktime"
                  hidden
                />
              </Button>
            )}
          </>
        )}
        {isEditImage && (
          <>
            {editImagePreviews.map((preview, index) => (
              <div
                className="relative"
                key={index}
                style={{
                  border: '2px solid #bbb',
                  borderRadius: '5px',
                }}
              >
                <div
                  className="absolute right-0 cursor-pointer"
                  onClick={() => handleDeleteEditImage(index)}
                >
                  <i className="bi bi-x-circle-fill text-primary" />
                </div>
                <Image
                  src={`${process.env.NEXT_PUBLIC_BASE_URL_WEB}${editImageUrl}/${preview}`}
                  alt={`Preview ${index}`}
                  width={66}
                  height={62}
                  style={{
                    objectFit: 'cover',
                    width: '66px',
                    height: '62px',
                  }}
                />
              </div>
            ))}
          </>
        )}
        {imagePreviews?.map((preview, index) => (
          <div
            className="relative"
            key={index}
            style={{
              border: '2px solid #bbb',
              borderRadius: '5px',
            }}
          >
            <div
              className="absolute right-0 cursor-pointer"
              onClick={() => handleDeleteImage(index)}
            >
              <i className="bi bi-x-circle-fill text-primary" />
            </div>
            <Image
              src={preview}
              alt={`Preview ${index}`}
              width={66}
              height={62}
              style={{
                objectFit: 'cover',
                width: '66px',
                height: '62px',
              }}
            />
          </div>
        ))}
        {videoPreviews?.map((preview, index) => (
          <div
            className="relative"
            key={index}
            style={{
              border: '2px solid #bbb',
              borderRadius: '5px',
            }}
          >
            <div
              className="absolute right-0 cursor-pointer"
              onClick={() => handleDeleteVideo(index)}
            >
              <i className="bi bi-x-circle-fill text-primary" />
            </div>
            <video
              width="66"
              height="62"
              src={preview}
              controls
              preload="auto"
              style={{ objectFit: 'cover', height: '62px' }}
            />
          </div>
        ))}
      </div>
    </>
  );
}
